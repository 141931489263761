import { defineMessages } from "react-intl"

export default defineMessages({
	chart: {
		id: "app.components.exchangeMobile.label.chart",
		defaultMessage: "Chart",
	},
	page_description: {
		id: "app.components.home.page_description",
		defaultMessage:
			"Українська криптобіржа BTC-Alpha з європейською ліцензією. Купівля, продаж та обмін криптовалюти за лічені хвилини",
	},
	top_trade: {
		id: "app.components.exchangeMobile.label.trade",
		defaultMessage: "Trade",
	},
	alpha_code: {
		id: "app.containers.alpha_codes.alpha_code",
		defaultMessage: "Alpha Code",
	},
	welcome_text: {
		id: "app.components.home.welcome_text",
		defaultMessage: "Welcome to BTC-Alpha",
	},
	welcome_text_desc: {
		id: "app.components.home.welcome_text_desc",
		defaultMessage: "Secure and reliable crypto asset trading platform",
	},
	learn_more: {
		id: "app.components.home.learn_more",
		defaultMessage: "Learn more",
	},
	alpha_feature_1: {
		id: "app.components.home.alpha_feature_1",
		defaultMessage: "Dynamic {breakingLine} system of fees",
	},
	alpha_feature_1_desc: {
		id: "app.components.home.alpha_feature_1_desc",
		defaultMessage: "Trade with fee {breakingLine} from 0.1%",
	},
	alpha_feature_2: {
		id: "app.components.home.alpha_feature_2",
		defaultMessage: "Security {breakingLine} of the highest level",
	},
	alpha_feature_2_desc: {
		id: "app.components.home.alpha_feature_2_desc",
		defaultMessage: "BTC-Alpha is TOP-15 {breakingLine} most secured exchanges in the world",
	},
	alpha_feature_3: {
		id: "app.components.home.alpha_feature_3",
		defaultMessage: "Fast deposit {breakingLine} and withdraw of funds",
	},
	alpha_feature_3_desc: {
		id: "app.components.home.alpha_feature_3_desc",
		defaultMessage: "Use the trusted {breakingLine} payment systems",
	},
	alpha_feature_4: {
		id: "app.components.home.alpha_feature_4",
		defaultMessage: "Referral {breakingLine} program",
	},
	alpha_feature_4_desc: {
		id: "app.components.home.alpha_feature_4_desc",
		defaultMessage: "Earn up to 50% {breakingLine} of referral commission",
	},
	alpha_feature_5: {
		id: "app.components.home.alpha_feature_5",
		defaultMessage: "Real time {breakingLine} quotes",
	},
	alpha_feature_5_desc: {
		id: "app.components.home.alpha_feature_5_desc",
		defaultMessage: "Exchange-traded asset data {breakingLine} without delay",
	},
	alpha_feature_6: {
		id: "app.components.home.alpha_feature_6",
		defaultMessage: "Support {breakingLine} 24/7",
	},
	alpha_feature_6_desc: {
		id: "app.components.home.alpha_feature_6_desc",
		defaultMessage: "24/7 available {breakingLine} support department",
	},
	trading_world: {
		id: "app.components.home.trading_world",
		defaultMessage: "Dive {breakingLine} into a cosmic world of trading",
	},
	trading_world_desc: {
		id: "app.components.home.trading_world_desc",
		defaultMessage: "Are you ready for take off? Quickly, fasten your seatbelts",
	},
	deposit_and_withdraw: {
		id: "app.components.home.deposit_and_withdraw",
		defaultMessage: "Fast {breakingLine} deposit and withdraw funds",
	},
	deposit_and_withdraw_desc: {
		id: "app.components.home.deposit_and_withdraw_desc",
		defaultMessage: "with help of payment services",
	},
	user_count_thousands: {
		id: "app.components.home.user_count_thousands",
		defaultMessage: "thousands",
	},
	user_count: {
		id: "app.components.home.user_count",
		defaultMessage: "of users",
	},
	stats_item_1: {
		id: "app.components.home.stats_item_1",
		defaultMessage: "$10+ mln",
	},
	stats_item_1_desc: {
		id: "app.components.home.stats_item_1_desc",
		defaultMessage: "Daily {breakingLine} volume",
	},
	stats_item_2: {
		id: "app.components.home.stats_item_2",
		defaultMessage: "5 years",
	},
	stats_item_2_desc: {
		id: "app.components.home.stats_item_2_desc",
		defaultMessage: "Experience of work {breakingLine} in crypto exchange",
	},
	stats_item_3: {
		id: "app.components.home.stats_item_3",
		defaultMessage: "{count}+",
	},
	stats_item_3_desc: {
		id: "app.components.home.stats_item_3_desc",
		defaultMessage: "Trading {breakingLine} pairs",
	},
	stats_item_4: {
		id: "app.components.home.stats_item_4",
		defaultMessage: "14 position",
	},
	stats_item_4_desc: {
		id: "app.components.home.stats_item_4_desc",
		defaultMessage: "In the exchange {breakingLine} security rating",
	},
	mobile_app_header: {
		id: "app.components.home.mobile_app_header",
		defaultMessage: "Trading without limits",
	},
	mobile_app_header_desc: {
		id: "app.components.home.mobile_app_header_desc",
		defaultMessage: "with a convenient BTC-Alpha app",
	},
	mobile_app_text_1: {
		id: "app.components.home.mobile_app_text_1",
		defaultMessage: "Get immediate news of the platform",
	},
	mobile_app_text_2: {
		id: "app.components.home.mobile_app_text_2",
		defaultMessage: "Watch the movement of market prices",
	},
	mobile_app_text_3: {
		id: "app.components.home.mobile_app_text_3",
		defaultMessage: "Stay updated about exchange quotes",
	},
	mobile_app_text_4: {
		id: "app.components.home.mobile_app_text_4",
		defaultMessage: "Trade in comfort and effective any time",
	},
	alpha_code_desc: {
		id: "app.components.home.alpha_code_desc",
		defaultMessage:
			"Alpha-Code is a tool for transferring assets between BTC-Alpha user accounts. {breakingLine} We have created Alpha-Code to make Your experience with BTC-Alpha crypto-exchange {breakingLine} as convenient, comfortable, and most importantly – safe as possible!",
	},
	news_subscribe: {
		id: "app.components.home.news_subscribe",
		defaultMessage: "Subscribe for the BTC-Alpha news",
	},
	news_subscribe_desc: {
		id: "app.components.home.news_subscribe_desc",
		defaultMessage: "Receive up-to-date news directly to your email. Without spam.",
	},
	welcome_to: {
		id: "app.components.home.welcome_to",
		defaultMessage: "Welcome to",
	},
	site_map: {
		id: "app.components.home.site_map",
		defaultMessage: "Site map",
	},
	feature_slider_interface: {
		id: "app.components.home.feature_slider_interface",
		defaultMessage: "Choose the interface to your taste",
	},
	coins: {
		id: "app.components.home.coins",
		defaultMessage: "Coins",
	},
	page_title: {
		id: "app.components.home.page_title",
		defaultMessage: "BTC-Alpha: Cryptocurrency Exchange",
	},
	page_title_desc: {
		id: "app.components.home.page_title_desc",
		defaultMessage:
			"BTC-Alpha is a new generation European cryptocurrency exchange. Provides services for trustful purchase, sale and transfer of your digital assets. It is included in the top 3 largest cryptocurrency exchanges in the Russian-speaking space, the top 100 cryptocurrency exchanges in terms of currency in the world and the top 15 in terms of security.",
	},
	mediapartners: {
		id: "app.components.home.mediapartners",
		defaultMessage: "Partners",
	},
	mediapartners_desc: {
		id: "app.components.home.mediapartners_desc",
		defaultMessage: "Only the most relevant and proven news about cryptocurrencies and blockchain",
	},
	demo_trading_slide: {
		id: "app.components.home.demo_trading_slide",
		defaultMessage: "Demo Trading",
	},
	demo_trading_slide_desc: {
		id: "app.components.home.demo_trading_slide_desc",
		defaultMessage: "Riskless demo trading",
	},
	demo_trading_slide_btn: {
		id: "app.components.home.demo_trading_slide_btn",
		defaultMessage: "Try now",
	},
	simple_steps_to_buying_cryptocurrency: {
		id: "app.components.home.simple_steps_to_buying_cryptocurrency",
		defaultMessage: "Simple steps to buying cryptocurrency",
	},
	register_on_our_platform: {
		id: "app.components.home.register_on_our_platform",
		defaultMessage: "Register on our platform",
	},
	complete_verification: {
		id: "app.components.home.complete_verification",
		defaultMessage: "Complete verification",
	},
	buy_cryptocurrency_or_make_a_deposit: {
		id: "app.components.home.buy_cryptocurrency_or_make_a_deposit",
		defaultMessage: "Buy cryptocurrency or make a deposit",
	},
	start_trading: {
		id: "app.components.home.start_trading",
		defaultMessage: "Start trading",
	},
	register: {
		id: "app.components.home.register",
		defaultMessage: "Register",
	},
	verification: {
		id: "app.components.home.verification",
		defaultMessage: "Verification",
	},
	buy_crypto: {
		id: "app.components.home.buy_crypto",
		defaultMessage: "Buy crypto",
	},
	trade: {
		id: "app.components.home.trade",
		defaultMessage: "Trade",
	},
	creating: {
		id: "app.components.home.creating",
		defaultMessage: "Creating your account with BTC Alpha has never been easier and if you have any questions our support is available 24/7 to guide you.",
	},
	email: {
		id: "app.components.home.email",
		defaultMessage: "Enter your email",
	},
	sign_up: {
		id: "app.components.home.sign_up",
		defaultMessage: "Sign up",
	},
	get_experience_in_trading: {
		id: "app.components.home.get_experience_in_trading",
		defaultMessage: "Get experience in trading without risks and losses",
	},
	if_you_are_a_beginner: {
		id: "app.components.home.if_you_are_a_beginner",
		defaultMessage:
			"If you are a beginner and want to learn how to trade, register and get free access to demo trading",
	},
	move_from_theory_to_practice: {
		id: "app.components.home.move_from_theory_to_practice",
		defaultMessage: "Move from theory to practice",
	},
	start_trading_risk_free_with_demo_trading_mega_bonus: {
		id: "app.components.home.start_trading_risk_free_with_demo_trading_mega_bonus",
		defaultMessage:
			"Start trading risk-free with Demo Trading Mega Bonus! Get it to your Demo account today only 10,000 USDT for registration!",
	},
	start_demo_trading: {
		id: "app.components.home.start_demo_trading",
		defaultMessage: "Start Demo Trading",
	},
	the_most_trusted_cryptocurrency_platform: {
		id: "app.components.home.the_most_trusted_cryptocurrency_platform",
		defaultMessage: "The most trusted cryptocurrency platform",
	},
	here_are_some_reasons_why_we_are_the_best: {
		id: "app.components.home.here_are_some_reasons_why_we_are_the_best",
		defaultMessage: "Here are some reasons why we are the best",
	},
	secure_your_cryptocurrency_transactions_with_vpn_proxy_master: {
		id: "app.components.home.secure_your_cryptocurrency_transactions_with_vpn_proxy_master",
		defaultMessage: "Secure your cryptocurrency transactions with VPN Proxy Master",
	},
	main_banner_title: {
		id: "app.components.home.main_banner_title",
		defaultMessage: "Make your future better. Invest in crypto",
	},
	main_banner_description: {
		id: "app.components.home.main_banner_description",
		defaultMessage: "Your Estimated Balance:",
	},
	deposit: {
		id: "app.components.home.deposit",
		defaultMessage: "Deposit",
	},
	choose: {
		id: "app.components.home.choose",
		defaultMessage: "Why Choose BTC-Alpha?",
	},
	main_tub_name_1: {
		id: "app.components.home.main_tub_name_1",
		defaultMessage: "24/7 Support",
	},
	main_tub_name_2: {
		id: "app.components.home.main_tub_name_2",
		defaultMessage: "360° Security",
	},
	main_tub_name_3: {
		id: "app.components.home.main_tub_name_3",
		defaultMessage: "Trading volume",
	},
	main_tub_name_4: {
		id: "app.components.home.main_tub_name_4",
		defaultMessage: "Low fees",
	},
	main_tub_content_title_1: {
		id: "app.components.home.main_tub_content_title_1",
		defaultMessage: "VIP 24/7 support",
	},
	main_tub_content_description_1: {
		id: "app.components.home.main_tub_content_description_1",
		defaultMessage: "From the moment you make first crypto transaction to your first NFT purchase, we will be with you throughout the entire process. Our support team is always available and will always speak to you in person - no more speaking to a bot!",
	},
	main_tub_content_description_2: {
		id: "app.components.home.main_tub_content_description_2",
		defaultMessage: "Enjoy access to our top of the line security features both for your account and our systems. Our system has proven countless times its resilience and integrity. We are audited and proven by many third party partners.",
	},
	main_tub_content_description_3_1: {
		id: "app.components.home.main_tub_content_description_3_1",
		defaultMessage: "Our trading volume is, on average, 200 million Dollars per day. This is all because of our strong customer base and their belief in our platform. You can see this via our",
	},
	main_tub_content_description_3_2: {
		id: "app.components.home.main_tub_content_description_3_2",
		defaultMessage: "or",
	},
	main_tub_content_description_3_3: {
		id: "app.components.home.main_tub_content_description_3_3",
		defaultMessage: "memberships.",
	},
	main_tub_content_description_4: {
		id: "app.components.home.main_tub_content_description_4",
		defaultMessage: "Our fees are different for all of our users - our VIP users can appreciate some of the best fees the market has to offer. Please find our trading fees here",
	},
	support_title: {
		id: "app.components.home.support_title",
		defaultMessage: "For beginners and professionals",
	},
	support_description: {
		id: "app.components.home.support_description",
		defaultMessage: "We have considered our solutions to support every stage of your personal growth. Our platform caters to both expert investors and total novices, no request it to complex or too easy.",
	},
	professional_terminal: {
		id: "app.components.home.professional_terminal",
		defaultMessage: "Professional Trading Terminal",
	},
	professional_terminal_description: {
		id: "app.components.home.professional_terminal_description",
		defaultMessage: "Our ‘Pro’ Trading Terminal provides all of the tools that a seasoned trader requires.",
	},
	try_it: {
		id: "app.components.home.try_it",
		defaultMessage: "Try it",
	},
	lite_version: {
		id: "app.components.home.lite_version",
		defaultMessage: "Lite Version (soon)",
	},
	lite_version_description: {
		id: "app.components.home.lite_version_description",
		defaultMessage: "Our ‘Lite’ version allows you to get acquainted with the Crypto world, without all of the complexities.",
	},
	soon: {
		id: "app.components.home.soon",
		defaultMessage: "Soon",
	},
	more_than: {
		id: "app.components.home.more_than",
		defaultMessage: "More than",
	},
	trust: {
		id: "app.components.home.trust",
		defaultMessage: "users trust us",
	},
	trust_description: {
		id: "app.components.home.trust_description",
		defaultMessage: "Our exchange is built on trust and security, our customers recognize this. Why not join them?",
	},
	get_started: {
		id: "app.components.home.get_started",
		defaultMessage: "Get Started",
	},
	k: {
		id: "app.components.home.k",
		defaultMessage: "K",
	},
	mln: {
		id: "app.components.home.mln",
		defaultMessage: "mln",
	},
	bln: {
		id: "app.components.home.bln",
		defaultMessage: "bln",
	},
	trn: {
		id: "app.components.home.trn",
		defaultMessage: "trn",
	},
	daily_volume: {
		id: "app.components.home.daily_volume",
		defaultMessage: "Daily volume",
	},
	years: {
		id: "app.components.home.years",
		defaultMessage: "years",
	},
	years_description: {
		id: "app.components.home.years_description",
		defaultMessage: "Experience of work in crypto exchange",
	},
	trading_pairs: {
		id: "app.components.home.trading_pairs",
		defaultMessage: "Trading pairs on the platform",
	},
	positions: {
		id: "app.components.home.positions",
		defaultMessage: "positions",
	},
	positions_description: {
		id: "app.components.home.positions_description",
		defaultMessage: "In the exchange security rating",
	},
	partners_check: {
		id: "app.components.home.partners_check",
		defaultMessage: "Check our awesome partners",
	},
	revolutionary: {
		id: "app.components.home.revolutionary",
		defaultMessage: "The Revolutionary Crypto Card",
	},
	revolutionary_description: {
		id: "app.components.home.revolutionary_description",
		defaultMessage: "Get the one that matches your vibe. Some cards are only available on subscription plans. Fees may apply.",
	},
	coming_soon_q3: {
		id: "app.components.home.coming_soon_q3",
		defaultMessage: "Coming soon in Q3",
	},
	mobile_app_title: {
		id: "app.components.home.mobile_app_title",
		defaultMessage: "Awesome Mobile App",
	},
	mobile_app_description: {
		id: "app.components.home.mobile_app_description",
		defaultMessage: "BTC-Alpha unlocks the potential of neo banking and trading platform.",
	},
	mobile_app_item_1: {
		id: "app.components.home.mobile_app_item_1",
		defaultMessage: "24/7 on the go Access",
	},
	mobile_app_item_2: {
		id: "app.components.home.mobile_app_item_2",
		defaultMessage: "Lite + Pro Versions",
	},
	mobile_app_item_3: {
		id: "app.components.home.mobile_app_item_3",
		defaultMessage: "Secure",
	},
	join_title: {
		id: "app.components.home.join_title",
		defaultMessage: "Join our community",
	},
	join_description: {
		id: "app.components.home.join_description",
		defaultMessage: "Join our community to discover the next alpha project and connect with fellow members!",
	},
	followers: {
		id: "app.components.home.followers",
		defaultMessage: "followers",
	},
})
